var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"creationCompetition"},[_c('el-breadcrumb',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticStyle:{"margin-left":"11px"},attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_vm._v("直播实训题管理")]),_c('el-breadcrumb-item',[_vm._v("商品库")]),_c('el-breadcrumb-item',[_vm._v("创建商品")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"creationCompetitionProgress"},[_c('div',{staticClass:"competitionProgress"},[_vm._m(0),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 1
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active > 1 ? 'active' : ''},[_vm._v("02")]),_c('span',[_vm._v("设置商品数据")])]),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 2
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active > 2 ? 'active' : ''},[_vm._v("03")]),_c('span',[_vm._v("设置脚本数据")])]),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 3
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active > 3 ? 'active' : ''},[_vm._v("04")]),_c('span',[_vm._v("设置商品优惠卷")])]),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 4
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active > 4 ? 'active' : ''},[_vm._v("05")]),_c('span',[_vm._v("设置商品互动问答")])]),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 5
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active > 5 ? 'active' : ''},[_vm._v("06")]),_c('span',[_vm._v("设置商品脚本分类")])]),_c('img',{staticClass:"step-wire",attrs:{"src":_vm.active > 6
              ? require('../../../assets/image/serviceData/blue.png')
              : require('../../../assets/image/serviceData/xian.png'),"alt":""}}),_c('div',{staticClass:"step-num"},[_c('span',{class:_vm.active >= 7 ? 'active' : ''},[_vm._v("07")]),_c('span',[_vm._v("创建成功")])])])]),_c('div',{staticClass:"creationCompetitionForm",staticStyle:{"height":"100%","flex":"1","min-height":"calc(100vh - 330px)"}},[_c('keep-alive',[_c('router-view')],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-num"},[_c('span',{staticClass:"active"},[_vm._v("01")]),_c('span',[_vm._v("设置商品信息")])])
}]

export { render, staticRenderFns }